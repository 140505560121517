import React from 'react';
import '../Productos/Products.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Products = () => {
  const products = [
    {
      id: 4,
      name: 'Asesoría y Gestión Sitio Web',
      description: 'Optimiza tu sitio web para los motores de búsqueda.',
      price: 'Desde $25.000',
      image: './img/asesoriaweb.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Asesor%C3%ADa%20y%20Gesti%C3%B3n%20Sitio%20Web*%F0%9F%8C%8E%F0%9F%99%8B%F0%9F%8F%BB%E2%80%8D%E2%99%82%EF%B8%8F' 
    },
    {
      id: 5,
      name: 'Contenido Redes Sociales',
      description: 'Personaliza tus redes sociales con estas plantillas.',
      price: 'Desde $10.000',
      image: './img/contenidoredes.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    },
    {
      id: 6,
      name: 'Asesoría Redes Sociales',
      description: 'Administración profesional de tus redes sociales.',
      price: 'Desde $30.000',
      image: './img/gestionredes.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Asesor%C3%ADa%20Redes%20Sociales*%F0%9F%93%B1' 
    },
    {
      id: 7,
      name: 'Campañas Publicitarias',
      description: 'Creación y manejo de publicidad en redes sociales.',
      price: 'Proximamente',
      image: './img/campañasredes.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    },
    {
      id: 8,
      name: <>Análisis<br />de Métricas</>,
      description: 'Informe del rendimiento de tus redes sociales.',
      price: 'Proximamente',
      image: './img/analisismetricas.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    },
    {
      id: 9,
      name: <>Diseño <br />Gráfico</>,
      description: 'Diseño de logos, banners, tarjetas de presentación, etc.',
      price: 'Desde $10.000',
      image: './img/diseño.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Dise%C3%B1o%20Gr%C3%A1fico*%F0%9F%8E%A8' 
    },
    {
      id: 10,
      name: <>Plantilla Web <br />Personalizada</>,
      description: 'Diseño Completo Personalizado de Plantilla Sitio Web.',
      price: '$24.000',
      image: './img/diseño-plantilla-web.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Dise%C3%B1o%20Gr%C3%A1fico*%F0%9F%8E%A8' 
    },
    {
      id: 11,
      name: <>Plantilla de Sitio <br />Web pre-hecha</>,
      description: 'Diseño de Plantilla Sitio Web en nuestro stock.',
      price: '$16.000',
      image: './img/plantilla-web.webp',
      link: 'https://api.whatsapp.com/send?phone=+56936619246&text=Hola!%20Quisiera%20una%20cotizaci%C3%B3n%20de%20*Dise%C3%B1o%20Gr%C3%A1fico*%F0%9F%8E%A8' 
    },
    {
      id: 12,
      name: <>Asesoría y Gestión<br />de Aplicaciones</>,
      description: 'Te asesoramos con la gestión de tu aplicación móvil.',
      price: '$25.000',
      image: './img/Aplicaciones.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    },
    {
      id: 13,
      name: <>Capacitaciones de<br />Tecnología</>,
      description: 'Te capacitamos en herramientas digitales para tu negocio.',
      price: '$40.000',
      image: './img/capacitacion-tecnologia.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    },
    {
      id: 14,
      name: <>Capacitaciones de<br />Diseño</>,
      description: 'Te capacitamos en herramientas de diseño para tu negocio.',
      price: '$40.000',
      image: './img/capacitacion-diseño.webp',
      link: 'https://digitalsolutionschile.cl/servicios' 
    }
  ];

  return (
    <div>
      <Navbar />
      <section className="product-section-products">
        <h2 className="section-title-products">Servicios, Asesorías & Capacitaciones.</h2>
        <div className="product-list-products">
          {products.map((product) => (
            <div key={product.id} className="product-card-products">
              <div className="image-container-products">
                <img src={product.image} alt={product.name} className="product-image-products" />
              </div>
              <h3 className="product-name-products">{product.name}</h3>
              <p className="product-description-products">{product.description}</p>
              <span className="product-price-products">{product.price}</span>
              <button
                className="buy-button-products"
                onClick={() => window.open(product.link, '_blank')} /* Abre el enlace en una nueva pestaña */
              >
                Cotizar
              </button>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Products;
